<template>
  <div>
    <big-title>
      Sobre ti<span>.</span>
    </big-title>

    <vx-card card-background="primary" content-color="white" title-color="white" title="Queremos saber sobre ti." class="">
      <div class="vx-col xxl:w-3/4 w-full">
        <p>
          Una vez completada tu verificación de identidad podrás consultar en esta sección tus datos y documentación general.
          <span class="bold">Completa este apartado, ya que de lo contrario no podrás acceder a diferentes secciones.</span>
        </p>
      </div>
    </vx-card>

    <div class="vx-row mt-5" v-if="mainComplianceStatus === 'running' && isMounted">
      <div class="vx-col w-full h-auto">
          <vx-card card-background="#3b3a3d" title="Hemos recibido tu información." title-color="white" >
          <p style="color: white; opacity: 0.75;line-height: 1.5rem" class="text-md">
            Ya falta muy poco para que puedas aceptar tu crédito
            Te pedimos estar atento a tu correo electrónico para recibir la confirmación de que tu cuenta ya está lista para operar.
            Este proceso puede tomar aproximadamente entre 5 y 10 minutos, y en algunos casos hasta 12 horas.
            Si tienes alguna duda sobre el proceso de verificación estamos para apoyarte
            a través del chat de la plataforma o en cualquiera de nuestras redes sociales.
          </p>
          </vx-card>
      </div>
    </div>

    <div class="vx-row mt-5" v-if="mainComplianceStatus === 'completed' && isMounted">
      <div class="vx-col w-full h-auto">
        <vx-card card-background="#3b3a3d" title="¡Ya puedes aceptar tu crédito!" title-color="white" >
          <p style="color: white; opacity: 0.75;line-height: 1.5rem" class="text-md">
            Tu cuenta en la Red está lista para que puedas aceptar tu crédito
          </p>
        </vx-card>
      </div>
    </div>

    <vx-card class="mt-5" v-if="mainComplianceStatus === 'pending' && isMounted">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mb-4">
            <div class="flex flex-wrap items-center">
              <p class="card-sub-title mr-5" v-if="mverficationNoSuccessful && !processWasStarted">
                La verificación de identidad no fue exitosa.
              </p>
              <p class="card-sub-title mr-5" v-else>
                Verificación de identidad.
              </p>
            </div>
          </div>

          <template v-if="!processWasStarted">
            <p v-if="mProcessIsPending">
              En la Red, es importante verificar que tus datos concuerden con tu identidad.
              Es por esto que utilizamos un software de reconocimiento de identidad a través de un proceso rápido y sencillo.
              Para iniciar con el proceso da clic en “Comenzar” y después lee con calma las indicaciones que tenemos
              para ti con el fin de que tu proceso sea exitoso.
            </p>
            <p v-if="mverficationNoSuccessful">
              <span class="bold">El proceso de verificación de identidad no fue exitoso.</span> Comienza nuevamente siguiendo las recomendaciones
              al pie de la letra y asegúrate de concluir el proceso correctamente. Principalmente cuida que tengas buena iluminación al momento de grabar
              tu rostro o que las fotografías de tus documentos sean legibles, es decir, que no se vean borrosas o con reflejos que no nos permitan
              leer la información claramente.
            </p>
          </template>
          <template v-else>
            <p>
              En la Red, es importante verificar que tus datos concuerden con tu identidad.
              Es por esto que utilizamos un software de reconocimiento de identidad a través de un proceso rápido y sencillo.
              Para iniciar con el proceso da clic en “Comenzar” y después lee con calma las indicaciones que tenemos
              para ti con el fin de que tu proceso sea exitoso.
            </p>
          </template>
        </div>
      </div>

      <div class="vx-row mt-3" v-if="isMounted && processWasStarted" >
        <div class="vx-col w-full">
          <vs-alert color="warning">
            <span class="bold">Estamos procesando tu información. Vuelve en unos minutos para consultar si el proceso fue exitoso.</span>
          </vs-alert>
        </div>
      </div>

      <div class="vx-row mt-3" v-if="isMounted && !processWasStarted" >
        <div class="vx-col w-full" v-if="mshouldRunMatiProcess">
          <vs-button class="ml-auto mt-2" @click="openMatiPopup" v-if="mverficationNoSuccessful">Comenzar nuevamente</vs-button>
          <vs-button class="ml-auto mt-2" @click="openMatiPopup" v-else>Comenzar</vs-button>
        </div>
      </div>
    </vx-card>

    <mati-popup :user-id-to-activate="UserId" @on-mati-process-started="onMatiProcessStarted" />
  </div>
</template>

<script>
import MatiPopup from "@components/mati/MatiPopup";
import FormsContainer from "@components/profile_shared/FormsContainer";
import userIdentityHelper from "@/helpers/userIdentityHelper";
import applicantInfoHelper from "@/helpers/applicantInfoHelper";
export default {
  name: "AboutYou",
  components: {
    MatiPopup,
    FormsContainer,
  },
  mixins: [userIdentityHelper,applicantInfoHelper],
  data() {
    return {
      isMounted: false,
      identity: null,
      status: null,
      processWasStarted: false,
      identityIsCompleted: false,
      isMoral: null,
      mainComplianceStatus: null,
    }
  },
  async mounted(){
    this.isMounted = false
    this.showLoading(true)
    await this.onUserInfoUpdated();
    await this.getIdentityInfo();
    await this.verifyCompliances();
    this.isMounted = true
    this.showLoading(false)
  },
  computed: {
    mProcessIsPending(){
      return this.matiIsPending(this.status);
    },
    mshouldRunMatiProcess(){
      return this.shouldRunMatiProcess(this.status);
    },
    mverificationIsCompleted(){
      return this.verificationIsCompleted(this.status);
    },
    mverificationIsRejected(){
      return this.verificationIsRejected(this.status);
    },
    mverificationIsInProcess(){
      return this.verificationIsInProcess(this.status);
    },
    mverificationisNewRequired(){
      return this.verificationisNewRequired(this.status);
    },
    mverificationIsReviewNeeded(){
      return this.verificationIsReviewNeeded(this.status);
    },
    mverficationNoSuccessful(){
      return this.mverificationIsRejected || this.mverificationisNewRequired || this.mverificationIsReviewNeeded;
    }
  },
  methods: {
    async reloadStatus(){
      this.showLoading(true)
      await this.getIdentityInfo();
      this.processWasStarted = false;
      this.showLoading(false)
    },
    async verifyCompliances(){
      // se verifica la identidad en general (ya sea p.fisica o p.moral)
      this.isMoral = this.userIsMoral(this.UserPersonType);
      let isForeign = this.userIsForeign(this.UserPersonType);

      // definir si mati esta pendiente o debe hacerse de nueva cuenta
      const shouldRunMati = this.shouldRunMatiProcess(this.status);
      if(shouldRunMati){
        this.mainComplianceStatus = "pending";
        return;
      }

      const matiIsRunning = this.matiIsRunning(this.status);
      if(matiIsRunning){
        this.mainComplianceStatus = "running";
        return;
      }

      // definir si esta completado
      const matiCompleted = this.verificationIsCompleted(this.status);
      if(matiCompleted){
        this.mainComplianceStatus = "completed";
      }
      else {
        this.mainComplianceStatus = "running";
      }
    },
    async onMatiProcessStarted(){
      this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
      this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", false);
      this.processWasStarted = true;
    },
    async openMatiPopup(){
      this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
      this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", true);
    },
    async getIdentityInfo(){
      this.status = await this.getUserIdentityStatus(this.UserId);
      this.identityIsCompleted = this.verificationIsCompleted(this.status);
    },
  }
}
</script>

<style scoped>

</style>